































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { formatDate, getStatusColor, downloadJson } from '@/utils';
import { defaultThemes, defaultPhases, defaultSettings, defaultSortOrder } from '../defaults';
import {
  RetrospectiveParticipant,
  RetrospectivePhase,
  RetrospectiveSettings,
  RetrospectiveSession,
  RetrospectiveColumns,
  RetrospectiveColumn,
  RetrospectiveCards,
  RetrospectiveCard,
  RetrospectiveCardComment,
  RetrospectiveCardActions,
  RetrospectiveCardAction,
  RetrospectiveType,
  RetrospectiveFilters,
  RetrospectiveTheme,
  User,
} from '../models';
import { retrospectiveService } from '../services';
import Participants from '@/components/Participants.vue';
import Timer from '@/components/Timer.vue';
import Settings from '../components/Settings.vue';
import Board from '../components/Board.vue';
import Actions from '../components/Actions.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({ components: { Participants, Timer, Settings, Board, Actions } })
export default class ViewSession extends Vue {
  async beforeRouteLeave(to, from, next) {
    await this.disconnect();
    next();
  }

  @Prop({ type: String, required: true })
  readonly id!: string;

  session: RetrospectiveSession | null = null;
  columns: RetrospectiveColumns = [];
  cards: RetrospectiveCards = [];
  config = {};
  edit = false;
  editIcon = 'mdi-pencil';
  phases = defaultPhases;
  sortOrder = defaultSortOrder;
  enableAdvancedSettings = false;
  formatDate = formatDate;
  getStatusColor = getStatusColor;
  timer: number | null = null;

  get background(): string | null {
    if (!this.session || !this.config || !this.config['types']) return null;
    return this.config['types'][this.session.type] ? this.config['types'][this.session.type].background : null;
  }
  get theme(): RetrospectiveTheme {
    const themeName = this.session && this.session['theme'] ? this.session.theme : 'Retro';
    return defaultThemes[themeName];
  }
  get type(): RetrospectiveType | null {
    if (!this.session || !this.config || !this.config['types']) return null;
    return this.config['types'][this.session.type];
  }
  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }
  get participant(): RetrospectiveParticipant {
    const participant: RetrospectiveParticipant = this.user;
    participant.owner = this.session ? this.session.owner : null;
    return participant;
  }
  get participants(): RetrospectiveParticipant[] {
    const participants = this.session && this.session.people ? Object.values(this.session.people) : [];
    return participants.sort((a, b) => a.username!.localeCompare(b.username!));
  }
  get isOwner(): boolean {
    return this.session ? this.userId === this.session.owner : false;
  }
  get settings(): RetrospectiveSettings {
    return this.session ? this.session.settings : defaultSettings();
  }
  get currentPhase(): RetrospectivePhase {
    return defaultPhases[this.session ? this.session.phase : 0];
  }
  get isReadOnly(): boolean {
    return this.session ? this.session.status === 'Closed' : false;
  }
  get participantReady(): boolean {
    return this.session ? this.session.people[this.userId].active : false;
  }
  get votes(): number {
    const votes = this.cards
      .filter((card) => card.votes && Object.keys(card.votes).includes(this.userId))
      .reduce((count, card) => count + card.votes![this.userId], 0);
    return votes;
  }
  get actions(): RetrospectiveCardActions {
    let actions = [] as RetrospectiveCardActions;
    if (this.cards) {
      this.cards.forEach((card) => card.actions.forEach((action) => actions.push(action)));
    }
    return actions;
  }
  get order(): number {
    return this.session ? this.session.order : 0;
  }
  get orderCriteria(): { fieldPath: string; direction: 'desc' | 'asc' } {
    let orderCriteria: { fieldPath: string; direction: 'desc' | 'asc' } = {
      fieldPath: 'createdOn',
      direction: 'asc',
    };
    if (this.session!.order === 1) {
      // Order by votes
      orderCriteria = { fieldPath: 'votesCount', direction: 'desc' };
    } else if (this.session!.order === 2) {
      // Order by creation date
      orderCriteria = { fieldPath: 'createdOn', direction: 'asc' };
    } else if (this.session!.order === 3) {
      // Order by creator
      orderCriteria = { fieldPath: 'createdBy.username', direction: 'asc' };
    }
    return orderCriteria;
  }

  @Watch('id', { immediate: true })
  async onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    await this.$bind('session', retrospectiveService.getSessionRef(id));
    //await this.$bind('columns', retrospectiveService.getColumnsRef(id));
    //await this.$bind('cards', retrospectiveService.getCardsRef(id));
    await this.$bind('config', retrospectiveService.getConfigRef());
    await this.connectUser(this.user);
    this.$bus.$emit('loading-indicator', false);
    if (this.session) {
      document.title = this.session.title;
      this.$analytics.logEvent('retro-view', { session_id: this.id, user_id: this.userId });
    }
  }

  @Watch('session.filters')
  async onFiltersChange(filters: RetrospectiveFilters, oldFilters: RetrospectiveFilters) {
    if (!this.session) return;
    if (
      oldFilters !== undefined &&
      filters.cardId === oldFilters.cardId &&
      filters.participantId === oldFilters.participantId &&
      filters.tag === oldFilters.tag
    )
      return; // Fix for annoying filter updates without actual changes!
    let creatorId = null as string | null;
    if (filters.participantId) {
      creatorId = filters.participantId;
    } else if (!this.session.settings.showAllCards && !this.isOwner) {
      creatorId = this.userId;
    }
    await this.reorderCards(creatorId);
    //console.log('Filters changed', filters, oldFilters, creatorId, this.session, this.cards);
  }

  @Watch('session.order')
  async onOrderChange(order: number, oldOrder: number) {
    if (!this.session) return;
    if (oldOrder !== undefined && order === oldOrder) return;
    const filters = this.session.filters;
    let creatorId = null as string | null;
    if (filters.participantId) {
      creatorId = filters.participantId;
    } else if (!this.session.settings.showAllCards && !this.isOwner) {
      creatorId = this.userId;
    }
    await this.reorderCards(creatorId);
    //console.log('Order changed', order, oldOrder, creatorId, this.session, this.cards);
  }

  @Watch('votes')
  async onVotesChange(votes: number, oldVotes: number) {
    if (!this.session) return;
    if (votes !== undefined && votes === oldVotes) return;
    let person = this.session.people[this.userId];
    if (votes == this.session.settings.maxVotes) {
      this.$bus.$emit('snackbar-notify', 'You have reached the maximum number of votes', 'warning');
      person.active = true;
      await retrospectiveService.updateParticipant(this.id, person);
    } else if (votes == this.session.settings.maxVotes - 1 && votes < oldVotes) {
      person.active = false;
      await retrospectiveService.updateParticipant(this.id, person);
    }
  }

  created() {
    //window.addEventListener('beforeunload', this.onBeforeUnload);
    window.onbeforeunload = this.onBeforeUnload;

    this.$bus.$off('retro-column-update').$on('retro-column-update', this.onRetroColumnUpdate);

    this.$bus.$off('retro-card-create').$on('retro-card-create', this.onRetroCardCreate);
    this.$bus.$off('retro-card-update').$on('retro-card-update', this.onRetroCardUpdate);
    this.$bus.$off('retro-card-delete').$on('retro-card-delete', this.onRetroCardDelete);
    this.$bus.$off('retro-card-upvote').$on('retro-card-upvote', this.onRetroCardUpvote);
    this.$bus.$off('retro-card-downvote').$on('retro-card-downvote', this.onRetroCardDownvote);

    this.$bus.$off('retro-card-comment-create').$on('retro-card-comment-create', this.onRetroCardCommentCreate);
    this.$bus.$off('retro-card-comment-delete').$on('retro-card-comment-delete', this.onRetroCardCommentDelete);

    this.$bus.$off('retro-card-action-create').$on('retro-card-action-create', this.onRetroCardActionCreate);
    this.$bus.$off('retro-card-action-delete').$on('retro-card-action-delete', this.onRetroCardActionDelete);

    this.$bus.$off('retro-card-tag-create').$on('retro-card-tag-create', this.onRetroCardTagCreate);
    this.$bus.$off('retro-card-tag-delete').$on('retro-card-tag-delete', this.onRetroCardTagDelete);

    this.$bus.$off('retro-action-update').$on('retro-action-update', this.onRetroActionUpdate);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
    this.$bus.$emit('loading-indicator', true);
  }

  onBeforeUnload() {
    this.disconnect();
  }

  async connectUser(user: RetrospectiveParticipant) {
    if (this.isReadOnly) return;
    const person: RetrospectiveParticipant = this.participant;
    person.lastJoinedOn = Date.now();
    await retrospectiveService.createParticipant(this.id, person);
  }
  async disconnectUser(user: RetrospectiveParticipant) {
    if (this.isReadOnly) return;
    await retrospectiveService.deleteParticipant(this.id, this.userId);
  }
  async disconnect() {
    await this.disconnectUser(this.user);
  }

  async selectParticipant(person: RetrospectiveParticipant | null) {
    //this.session!.filters.participantId = person.id;
    const personId = person ? person.id : null;
    await retrospectiveService.updateSession(this.id, { ['filters.participantId']: personId });
  }

  async toggleSetting(name, value) {
    //console.log('Toggled setting', name, value);
    await retrospectiveService.updateSession(this.id, { [name]: value });
  }

  toggleEdit() {
    this.edit = !this.edit;
    this.editIcon = this.edit ? 'mdi-pencil-off' : 'mdi-pencil';
  }

  async changePhase(phaseString: string) {
    if (!this.session) return;
    let status = this.session.status;
    let order = this.session.order;
    let phase = Number(phaseString);
    let settings = this.session.settings;
    let people = this.session.people;
    // Reset active participants
    for (const personId in people) {
      people[personId].active = false;
    }
    switch (phase) {
      // @TODO: Refactor this logic, move settings in the model or defaults!
      case 0: // Start
        status = 'Active';
        settings.enableComments = false;
        settings.enableActions = false;
        settings.enableTags = false;
        settings.enableVoting = false;
        settings.enableEditing = false;
        settings.showAllCards = false;
        settings.showAllVotes = false;
        break;
      case 1: // Reflect
        status = 'Active';
        settings.enableComments = false;
        settings.enableActions = false;
        settings.enableTags = false;
        settings.enableVoting = false;
        settings.enableEditing = true;
        settings.showAllCards = false;
        settings.showAllVotes = false;
        break;
      case 2: // Review
        status = 'Active';
        settings.enableComments = true;
        settings.enableActions = false;
        settings.enableTags = true;
        settings.enableVoting = false;
        settings.enableEditing = false;
        settings.showAllCards = true;
        settings.showAllVotes = false;
        break;
      case 3: // Vote
        status = 'Active';
        settings.enableComments = true;
        settings.enableActions = false;
        settings.enableTags = true;
        settings.enableVoting = true;
        settings.enableEditing = false;
        settings.showAllCards = true;
        settings.showAllVotes = false;
        break;
      case 4: // Discuss
        order = 1; // Order by votes
        status = 'Active';
        settings.enableComments = true;
        settings.enableActions = true;
        settings.enableTags = true;
        settings.enableVoting = false;
        settings.enableEditing = false;
        settings.showAllCards = true;
        settings.showAllVotes = true;
        break;
      case 5: // Finish
        status = 'Closed';
        settings.enableComments = false;
        settings.enableActions = false;
        settings.enableTags = false;
        settings.enableVoting = false;
        settings.enableEditing = false;
        settings.showAllCards = true;
        settings.showAllVotes = true;
        break;
    }
    await retrospectiveService.updateSession(this.id, {
      settings,
      status,
      phase,
      order,
      people,
    });
  }
  async orderCards() {
    if (!this.session) return;
    await retrospectiveService.updateSession(this.id, { order: this.session.order });
  }
  async reorderCards(creatorId: string | null) {
    if (!this.session) return;
    const where = { creatorId: creatorId };
    const orderBy = { fieldPath: this.orderCriteria.fieldPath, direction: this.orderCriteria.direction };
    await this.$bind('columns', await retrospectiveService.getColumnsRef(this.session.id));
    await this.$bind('cards', await retrospectiveService.filterCards(this.session.id, where, orderBy));
    // Override card ordering
    //if (this.order > 0) {
    let orderedCardIds = {};
    this.cards.forEach((card) =>
      this.columns.forEach((column) => {
        if (!orderedCardIds[column.id]) orderedCardIds[column.id] = [];
        if (column.cardIds && column.cardIds.includes(card.id)) orderedCardIds[column.id].push(card.id);
      })
    );
    this.columns.forEach((column) => (column.cardIds = orderedCardIds[column.id]));
    //}
  }

  async setSessionStatus(status: string) {
    if (!this.session) return;
    this.session.status = status;
    if (status === 'Active') {
      this.session.completedOn = null;
      this.session.startedOn = Date.now();
    } else if (status === 'Closed') {
      this.session.completedOn = Date.now();
    }
    // TODO: Check if the above code is needed, or we can just pass the changes to the service and reactively propagate them
    await retrospectiveService.updateSession(this.session.id, {
      status: this.session.status,
      startedOn: this.session.startedOn,
      completedOn: this.session.completedOn,
    });
  }

  async exportSession() {
    if (!this.session) return;
    await retrospectiveService.exportSession(this.session);
    this.$bus.$emit('snackbar-notify', 'Retrospective successfully exported', 'success');
  }
  async exportActions() {
    if (!this.session) return;
    await retrospectiveService.exportActions(this.session, this.actions);
    this.$bus.$emit('snackbar-notify', 'Retrospective actions successfully exported', 'success');
  }

  async updateTimer(time) {
    if (!this.session) return;
    await retrospectiveService.updateSession(this.session.id, { timer: time });
  }

  async toggleParticipantReady() {
    if (this.isReadOnly || !this.session) return;
    let person = this.session.people[this.userId];
    person.active = !person.active;
    retrospectiveService.updateParticipant(this.id, person);
  }

  // Event listeners
  async onRetroColumnUpdate(columnId: string, columnData: Partial<RetrospectiveColumn>) {
    //console.log('Retro column update', columnId, column);
    await retrospectiveService.updateColumn(this.id, columnId, columnData);
  }
  async onRetroCardCreate(card: RetrospectiveCard, column: RetrospectiveColumn) {
    //console.log('Retro card create', card, column);
    await retrospectiveService.createCard(this.id, card, column);
  }
  async onRetroCardUpdate(cardId: string, cardData: Partial<RetrospectiveCard>) {
    //console.log('Retro card update', card);
    await retrospectiveService.updateCard(this.id, cardId, cardData);
  }
  async onRetroCardDelete(card: RetrospectiveCard, column: RetrospectiveColumn) {
    //console.log('Retro card delete', card);
    await retrospectiveService.deleteCard(this.id, card.id, column);
  }
  async onRetroCardUpvote(card: RetrospectiveCard) {
    //console.log('Retro card upvote', card);
    await retrospectiveService.upvoteCard(this.id, card.id, this.userId);
  }
  async onRetroCardDownvote(card: RetrospectiveCard) {
    //console.log('Retro card downvote', card);
    await retrospectiveService.downvoteCard(this.id, card.id, this.userId);
  }

  async onRetroCardCommentCreate(card: RetrospectiveCard, comment: RetrospectiveCardComment) {
    //console.log('Retro card comment create', card);
    await retrospectiveService.createComment(this.id, card.id, comment);
  }
  async onRetroCardCommentDelete(card: RetrospectiveCard, comment: RetrospectiveCardComment) {
    //console.log('Retro card comment delete', card, comment);
    await retrospectiveService.deleteComment(this.id, card.id, comment);
  }

  async onRetroCardActionCreate(card: RetrospectiveCard, action: RetrospectiveCardAction) {
    //console.log('Retro card action create', card, action);
    await retrospectiveService.createAction(this.id, card.id, action);
  }
  async onRetroCardActionDelete(card: RetrospectiveCard, action: RetrospectiveCardAction) {
    //console.log('Retro card action delete', card, action);
    await retrospectiveService.deleteAction(this.id, card.id, action);
  }

  async onRetroCardTagCreate(card: RetrospectiveCard, tag: string) {
    //console.log('Retro card tag create', card, tag);
    await retrospectiveService.createTag(this.id, card.id, tag);
  }
  async onRetroCardTagDelete(card: RetrospectiveCard, tag: string) {
    //console.log('Retro card tag delete', card, tag);
    await retrospectiveService.deleteTag(this.id, card.id, tag);
  }

  async onRetroActionUpdate(card: RetrospectiveCard, action: RetrospectiveCardAction) {
    if (!card || !action) return;
    //console.log('Retro action update', card, action);
    try {
      await retrospectiveService.updateCard(this.id, action.cardId, { actions: card.actions });
      this.$bus.$emit('snackbar-notify', 'Retrospective action item successfully updated', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
